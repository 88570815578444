@import "~antd/dist/antd.css";

body {
  background-color: #f6f9fc;
}

.ant-form-item {
  margin-bottom: 10px;
}

.logo {
  width: 120px;
  height: 120px;
}

.main {
  max-width: 960px;
  margin: 0 auto;
}

.steps-container {
  max-width: 500px;
  margin: 1rem auto;
}

.deposit-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 2rem 0rem 2rem;
//   .ant-card-bordered {
//     border-top: 2px solid #5ea4f3;
//   }
//   .ant-card {
//     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
//   }
  //   .ant-input {
  //     border: 0;
  //     border-radius: 6px;
  //     box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
  //       0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  //   }
}

.locale-chooser {
  float: right;
  margin-top: 1rem;
  margin-right: 1rem;
  text-align: right;
}

.infos {
  .info-item {
    span {
      padding: 0 0.5rem;
    }
  }
}

// mobile device
@media only screen and(max-width: 47.999em) {
  .logo {
    width: 100px;
    height: 100px;
  }

  .steps-container {
    margin: 0rem 1rem;
  }

  .deposit-container {
    padding: 0;
  }

  .locale-chooser {
    display: none;
  }

  .deposit-container {
    .ant-card-bordered {
      border-top: 1px solid #e8e8e8;
      border-left: none;
      border-right: none;
    }
  }
}
